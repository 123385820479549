import axios from '@axios'

export default {
  async getAbrechnungAdmin() {
    try {
      const r = await axios.get('abrechnung/getAbrechnungAdmin', {
        params: {
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getAbrechnungMonatAdmin(jahr, monat) {
    try {
      const r = await axios.get('abrechnung/getAbrechnungMonatAdmin', {
        params: {
          jahr,
          monat,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async createAbrechnung(mandantID, jahr, monat) {
    try {
      const r = await axios.get('abrechnung/getAbrechnungGenerate', {
        params: {
          mandantID,
          jahr,
          monat,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getAbrechnungDatei(abrechnungID, welche) { // Download...
    try {
      const r = await axios.get('abrechnung/getAbrechnungDatei', {
        responseType: 'blob',
        headers: { Accept: 'application/pdf' },
        params: {
          abrechnungID,
          welche,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
    }
    return null
  },
  async getAbrechnungUpdate(abrechnungID, status) {
    try {
      const r = await axios.get('abrechnung/getAbrechnungUpdate', {
        params: {
          abrechnungID,
          status,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
    }
    return null
  },
  async AbrechnungSendMail(abrechnungID, datei) {
    try {
      const r = await axios.get('abrechnung/getAbrechnungSendMail', {
        params: {
          abrechnungID,
          datei,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getAbrechnungMandant(mandantID) { // Get mandant abrechnungen bei Mandant...
    try {
      const r = await axios.get('abrechnung/getAbrechnungMandantGet', {
        params: {
          mandantID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
}
